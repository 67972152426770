import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo({fill}) {

    //for now, to detect light and dark mode, there may be context to consume?
    const light = (fill === '#000');

    const fillStyle = light ?
        {fill: '#f44949'}
        : {fill: '#fff'};

    return (
        <svg height="35px" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.968 117.947">
            <title>logo</title>
            <g>
                <path
                    d="M24.889,115.665q-2.212-2.282-2.212-8.642a85.779,85.779,0,0,1,.9-10.993q.9-6.7,2.972-18.459,3.594-21.706,5.669-37.61a138.05,138.05,0,0,0,1.245-16.593q0-6.222-4.563-6.222-3.6,0-8.642,3.664T9.126,32.494q-1.938,2.628-4.287,2.627a4.219,4.219,0,0,1-3.387-1.867A6.685,6.685,0,0,1,0,29.037a12.173,12.173,0,0,1,.9-4.494,24.96,24.96,0,0,1,2.973-5.185,52.245,52.245,0,0,1,14.8-14.311A30.8,30.8,0,0,1,34.983,0q7.6,0,11.684,4.839t4.079,14.1a66,66,0,0,1-.691,8.781q-.694,5.049-1.245,8.227t-.83,4.424q7.883-22.534,17.7-31.457T87.25,0q8.987,0,14.173,6.36t5.185,17.976a64.813,64.813,0,0,1-1.244,11.338Q112.276,16.593,121.542,8.3A30.983,30.983,0,0,1,142.836,0q9.676,0,14.864,6.222t5.185,17.423a75.279,75.279,0,0,1-1.8,14.795l-8.435,39.546a61.979,61.979,0,0,0-1.382,11.891q0,5.808,2.7,8.711a9.774,9.774,0,0,0,7.536,2.9,15.4,15.4,0,0,0,8.918-3.042,58.44,58.44,0,0,0,10.3-10.094,4.854,4.854,0,0,1,3.733-1.936,3.2,3.2,0,0,1,2.835,1.66,8.579,8.579,0,0,1,1.037,4.563q0,5.392-2.627,8.573-7.192,8.711-14.243,12.721a29.5,29.5,0,0,1-14.795,4.01q-11.754,0-18.39-6.983t-6.637-18.736q0-8.157,3.318-23.645l5.531-25.3q.276-1.244.968-4.425t1.107-6.014a38.679,38.679,0,0,0,.414-5.6q0-4.839-1.867-7.26a6.332,6.332,0,0,0-5.323-2.419q-6.5,0-13.62,8.365T108.613,53.65q-6.429,19.361-10.439,50.331-.969,8.3-3.388,11.131t-7.812,2.835q-4.7,0-6.845-2.213t-2.143-8.3a95.181,95.181,0,0,1,1.521-14.864q1.519-9.195,3.871-21.64,6.36-33.462,6.361-43.141,0-5.253-2.005-7.744a7.264,7.264,0,0,0-6.015-2.488q-6.775,0-13.965,7.674T53.719,52.475q-6.845,19.567-11.407,52.889-.969,7.051-2.835,9.817t-7.26,2.766Q27.1,117.947,24.889,115.665Z"
                    style={fillStyle}/>
                <path d="M202.36,114.628a12.224,12.224,0,0,1-3.11-8.711q0-6.222,3.525-9.956t9.887-3.733q6.083,0,9.195,2.973t3.111,8.78q0,6.362-3.595,10.163t-9.818,3.8Q205.469,117.947,202.36,114.628Z" style={fillStyle}/>
            </g>
        </svg>
    );
}